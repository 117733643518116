var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.SSearchShow)?_c('s-search',{ref:"SSearch",attrs:{"queryItems":_vm.queryItems},on:{"fetchData":_vm.fetchUsers}}):_vm._e(),_c('div',{staticClass:"table-button table-button-flex"},[_c('div',[_c('a-button',{attrs:{"type":"plain","icon":"redo"},on:{"click":function($event){return _vm.$refs.table.refresh(true)}}},[_vm._v("刷新")]),_c('columns-set',{attrs:{"tableName":"UndispatchedOrder","columns":_vm.columns},on:{"changeColumns":_vm.changeColumns}})],1)]),_c('s-table',{ref:"table",attrs:{"size":"small","rowKey":"cdId","columns":_vm.tableColumns,"data":_vm.loadData,"showPagination":true,"scroll":{ x: '1000px', y: 'calc(82vh - 150px )' },"tableLayout":"fixed"},scopedSlots:_vm._u([{key:"tworksite",fn:function(text){return _c('span',{},[_c('div',[_vm._v(_vm._s(text.wsName))])])}},{key:"cdPId",fn:function(text, record){return _c('span',{},[_c('div',[_vm._v(_vm._s(record.tproject ? record.tproject.pname : ""))])])}},{key:"tproject",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(text ? (text.tworkerType ? text.tworkerType.wtType : "") : "")+" ")])}},{key:"cdExpectStartTime",fn:function(text, record){return _c('span',{},[(text == '')?_c('div',[_vm._v(_vm._s("-"))]):_c('div',[_vm._v(" "+_vm._s(_vm.$Format(text).slice(0, 10))+" ~ "+_vm._s(_vm.$Format(record.cdExpectEndTime).slice(0, 10))+" ")])])}},{key:"action",fn:function(text, record){return _c('span',{staticClass:"action"},[(
          _vm.isShowBtns.indexOf(
            'UndispatchedOrder-/api/constructionDetail/update'
          ) > -1
        )?_c('a',{staticClass:"editBtn",on:{"click":function($event){return _vm.showDrawer('AssignWorkerShow', record, '指定工人')}}},[_vm._v("指定")]):_vm._e(),(
          _vm.isShowBtns.indexOf(
            'UndispatchedOrder-/api/constructionDetail/update'
          ) > -1
        )?_c('a',{on:{"click":function($event){return _vm.showDrawer('SendOrdersShow', record, '设置派单规则')}}},[_vm._v("派单")]):_vm._e()])}}])}),_c('a-drawer',{attrs:{"title":_vm.DrawerTitle,"placement":"right","closable":true,"visible":_vm.visibleDrawer,"after-visible-change":_vm.afterVisibleChange,"width":750,"destroyOnClose":true},on:{"close":_vm.onClose}},[(_vm.AssignWorkerShow)?_c('AssignWorker',{ref:"AssignWorker",attrs:{"record":_vm.record},on:{"onClose":_vm.onClose,"recordChangeFn":_vm.recordChangeFn}}):_vm._e(),(_vm.SendOrdersShow)?_c('SendOrders',{ref:"SendOrders",attrs:{"record":_vm.record,"AssignWorkerValue":_vm.AssignWorkerValue},on:{"onClose":_vm.onClose}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }